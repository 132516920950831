.orderProductBox {
    gap: 20px;
    padding: 15px 0;
}

.orderProductBox+.orderProductBox {
    border-top: 1px solid #ddd;
}

.orderProductBox__img {}

.orderProductBoxCntnt {
    flex: 1;
    gap: 1em;
}

.orderProductBoxCntnt__qntty,
.orderProductBoxCntnt__price {
    flex: 0 0 auto;
    /* max-width: 130px; */
}

.orderProductBoxCntnt__link {
    flex: 1;
}

.packingslip,
.packingslip * {
    font-family: Arial, Helvetica, sans-serif !important;
}

.ordertabular {
    --width: 120px;
}

.ordertabular__title {
    flex: 0 0 var(--width);
    max-width: var(--width);

}

.ordertabular__cntnt {
    flex: 0 0 calc(100% - var(--width));
}

.documentHolderBox {
    padding: 8px;
    box-shadow: 0px 7px 20px 0 rgba(0, 0, 0, 0.08);
}

.documentHolderBox__icon {
    padding: 9px;
    border: 1px solid #ccc;
    margin: 0;
}

.documentHolderBox__icon svg {
    display: block;
}

.documentHolderBox__cntnt {

    padding-left: 8px;
}


@media all {
    .page-break {
        display: none;
    }
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    .packingslip,
    .packingslip * {
        color: #000;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 20mm;
}

.productPackegingSlip {
    display: flex;
}

.productPackegingSlip::after {
    content: '';
    clear: both;
    display: table;
}

.productPackegingSlip__img {
    float: left;
    width: 50px;
    padding: 3px;
    border: 1px solid #fff;

}

.productPackegingSlip__img img {
    width: 100%;
}

.productPackegingSlip__cntnt {
    width: calc(100% - 50px);
    float: left;
}

.textCenter {
    text-align: center;
}

.floatLeft {
    float: left;
}

.printordertable__Left {
    width: 65%;
}

.printordertable__right {
    width: 33%;
}

.order_status_outer .mini .rdw-option-wrapper {
    height: auto;
}

.status_description {
    padding: 10px;
    border: #eee 1px solid;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
}

.status_description p {
    margin-bottom: 5px;
}

.status_description p a {
    color: blue;
}

@media screen and (min-width: 575px) {
    .status_description {
        white-space: pre-wrap;
    }
}

@media only screen and (max-width: 575px) {
    .orderProductBoxCntnt {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
    }

    .orderProductBoxCntnt>div:first-child {
        grid-column-end: 3;
        grid-column-start: 1;
    }
}


@media (prefers-color-scheme: dark) {
    .packingslip,
    .packingslip * {
        color: #000;
    }
}