.blogListBox {
    padding: 40px 0;
}

.blogListBox+.blogListBox {
    border-top: 1px solid #D6D6D6;

}

.blogListBox__cntnt {
    padding: 0 0 0 30px;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
}


.blogDetailTitle__img {
    width: 100%;
}

.blogDetailTitle {
    margin-bottom: 24px;
}

.blogDetailTitle__cntnt {}


.blogDetailCntnt img {
    margin-bottom: 1rem;
}

.blogDetailCntnt a {
    color: var(--buttonColor);
}

.blogpostBoxList .blogpostBox_cntnt {
    padding: 14px 15px;
}

.blogpostBox.blogpostBoxList {
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.08);
}

.customRadioButton input {
    display: none;
}

.customRadioButton label {
    cursor: pointer;
    padding: 5px;
}

.customRadioButton input:checked+label {
    color: var(--buttonColor);
}

@media screen and (max-width:767px){
    .blogListBox {
        padding: 20px 0;
    }
}
@media screen and (max-width:575px){
    .blogListBox__cntnt {
        padding: 10px 0 0;
        max-width: 100%;
    }
}