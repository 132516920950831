.custom_input_group.input-group.registerPhone .react-tel-input .form-control.invalid-number {
	background: #ff000014;
	border-color: #dc3545;
}

.registerPhone .react-tel-input .flag-dropdown {
	top: 1px;
	bottom: 1px;
	padding: 0;
	background-color: transparent !important;
	border: 0;
	border-radius: 3px 0 0 3px;
	left: 1px;
}

.custom_input_group.input-group .form-control+.input-group-text {
	left: auto;
	right: 0;
}

.password_bar_outer {
	position: relative;
	width: 100%;
	z-index: 9;
}

.password_bar {
	position: absolute;
	top: -4px;
	left: 0;
	width: 100%;
	height: 3px;
	border-radius: 0;
	background-color: #eee;
	overflow: hidden;
}

.password_bar span {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
}