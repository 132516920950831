.dashboardStat {
  padding: 20px 20px 15px 20px;
  color: #fff;

}

.dashboardStat__row {
  display: flex;
  --width: 45px;
}

.dashboardStat__icon {
  flex: 0 0 var(--width);
  max-width: var(--width);
  width: var(--width);
  height: var(--width);
  background: #f0f0f0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
}

.dashboardStat__cntnt {
  flex: 0 0 calc(100% - var(--width));
  max-width: calc(100% - var(--width));
  padding-left: 12px;
  color: #fff;
}

.dashboardStat__cntnt sup {
  top: -.2em;
  margin: 0;
  font-weight: normal;
}

.small.datepicker {
  font-size: 14px;
}

.main_outer .react-datepicker__header {
  background-color: transparent;
  border: 0;
}

.main_outer .react-datepicker__current-month {
  text-align: left;
  padding: 0 12px;
  color: #878c91;
  font-weight: normal;
  font-size: 14px;
}

.main_outer .react-datepicker__navigation--previous {
  right: 40px;
  left: auto;
}

.main_outer .react-datepicker__year-read-view--down-arrow,
.main_outer .react-datepicker__month-read-view--down-arrow,
.main_outer .react-datepicker__month-year-read-view--down-arrow,
.main_outer .react-datepicker__navigation-icon::before {
  border-color: #997878;
  width: 8px;
  height: 8px;
}

.main_outer .react-datepicker__navigation {
  top: 10px;
}

.main_outer .react-datepicker__triangle {
  display: none !important;
}

.main_outer .react-datepicker {
  border: 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px;
}

.main_outer .react-datepicker__day,
.main_outer .react-datepicker__month-text,
.main_outer .react-datepicker__quarter-text,
.main_outer .react-datepicker__year-text {
  border-radius: 50%;
  margin: 2px;
}

.main_outer .react-datepicker__day--today,
.main_outer .react-datepicker__month-text--today,
.main_outer .react-datepicker__quarter-text--today,
.main_outer .react-datepicker__year-text--today,
.main_outer .react-datepicker__day:hover,
.main_outer .react-datepicker__month-text:hover,
.main_outer .react-datepicker__quarter-text:hover,
.main_outer .react-datepicker__year-text:hover {
  font-weight: normal;
  background-color: #f1592a;
  color: #fff;
}

.main_outer .react-datepicker__month {
  margin: 0 10px;
}

.main_outer .react-datepicker__day-names {
  margin-top: 10px;
}

.main_outer .react-datepicker__header .main_outer .react-datepicker__day-name,
.main_outer .react-datepicker__day--outside-month {
  color: #878c91;
}

@media screen and (max-width: 1299px) {
  .dashboardStat {
    padding: 15px 10px;
  }

  .dashboardStat__row {
    --width: 35px;
  }

  .dashboardStat__icon {
    border-radius: 9px;
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .dashboardStat {
    padding: 10px;
  }

  .dashboardStat__icon {
    flex: 0 0 35px;
    max-width: 35px;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    font-size: 14px;
  }
}

@media (prefers-color-scheme: dark) {
  .dashboardStat__icon{
    color: #000;
  }
}