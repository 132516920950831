@media only screen and (min-width: 992px) {
    .ordersTable .MuiDataGrid-main {
        overflow: auto;
    }

    .orderPage .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.css-xaep3o-MuiPaper-root-MuiCard-root,
    .ordersTable .MuiDataGrid-columnHeaders,
    .ordersTable .MuiDataGrid-virtualScroller {
        overflow: unset !important;
        position: static;
        margin: 0 !important;
    }

    .ordersTable .MuiDataGrid-cellCheckbox,
    .ordersTable .MuiDataGrid-cell--withRenderer:nth-child(1),
    .ordersTable .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1) {
        position: sticky;
        top: 0;
        background: var(--white);
        z-index: 9;
        left: 0;
    }

    .ordersTable .MuiDataGrid-cell--withRenderer:nth-child(1) {
        padding-left: 0;
        box-shadow: rgb(0 0 0 / 5%) 2px 3px 8px;
    }

    .ordersTable .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1) {
        box-shadow: rgb(0 0 0 / 5%) 2px 3px 8px;
        max-height: 70px;
        top: 2px;
    }

    .ordersTable .MuiDataGrid-cellCheckbox,
    .ordersTable .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1) {
        left: 0;
    }


    .ordersTable .MuiDataGrid-columnHeadersInner {
        position: relative;
        transform: none !important;
    }

    .ordersTable .MuiDataGrid-cell--withRenderer {
        overflow: visible !important;
    }

}

.refundProdCol {
    align-items: center;
}

.refundProdCol+.refundProdCol {
    margin-top: 6px;
}

.MuiDataGrid-columnHeadersInner.MuiDataGrid-columnHeadersInner--scrollable {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.user_search_box {
    /* max-height: 200px;
    overflow: hidden;
    overflow-y: auto; */
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    height: auto;
    background-color: var(--white);
    z-index: 999;
    border: #cecece 1px solid;
    padding: 10px;
}

.fixed_tel_input .react-tel-input .country-list {
    position: fixed;
}

.refund_timeline {
    padding-left: 20px;
    padding-bottom: 15px;
    position: relative;
}

.refund_timeline:after {
    content: "";
    background: #e7e7e7;
    width: 2px;
    height: 100%;
    position: absolute;
    left: 4px;
    bottom: 0;
}

.refund_timeline:before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    background: #747474;
    border-radius: 50%;
    left: 0;
    top: 0px;
    z-index: 1;
}

.refund_timeline .ordertabular_summary .ordertabular__cntnt {
    white-space: nowrap;
}

.tracking_textarea textarea {
    max-height: 150px;
    overflow-y: auto !important;
}

.order_status_outer .MuiRadio-colorSecondary {
    padding: 5px;
}

.order_status_outer {
    width: 280px;
}

.no_order_overlay .discountTable .MuiDataGrid-main div {
    position: static !important;
}

.no_order_overlay .ordersTable .MuiDataGrid-main {
    overflow: hidden;
}

.no_order_overlay .discountTable .MuiDataGrid-main div.MuiDataGrid-columnHeaders,
.no_order_overlay .discountTable .MuiDataGrid-main div.MuiDataGrid-virtualScrollerContent,
.no_order_overlay .discountTable .MuiDataGrid-main .Mui-resizeTriggers {
    display: none;
}

.MuiDataGrid-main>div {
    width: 100% !important;
}

.filter_search_panel.ml-0 {
    margin-left: 0 !important;
}

@media only screen and (min-width: 900px) {
    .searchPanelOrder.searchPanelOrder {
        flex-grow: 1;
    }

    .searchPanelOrder.searchPanelOrder input.MuiInputBase-input,
    .searchPanelOrder.searchPanelOrder .MuiInputBase-root {
        width: 100%;
    }
}

@media only screen and (max-width: 890px) {
    .responsiveFixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: var(--white);
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        z-index: 99;
        box-shadow: 0px -9px 20px rgba(0, 0, 0, 0.1);
    }

    .responsiveFixed>div {
        justify-content: center;
    }
}

@media only screen and (max-width: 599px) {
    .mobilescrollMenu>div {
        border-bottom: 1px solid #ddd;
    }

    .filter_search_panel.searchPanelOrder .MuiInputBase-root {
        width: 100%;
    }
}