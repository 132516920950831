/*Cart page css strat*/

.table-responsive {
    position: relative;
    z-index: 1;
    padding: 0 15px 0 0;
}

.cart-title {
    padding: 0 0;
    margin-bottom: 30px;
}

.yourCartTableOuter table {
    /* color: var(--darkTint1); */
    font-size: 14px;
    margin: 0;
    width: 100%;
}

.yourCartTableOuter table th {
    /* color: var(--headingColor); */
    font-size: 14px;
    font-weight: 900;
    text-align: left;
    padding: 10px 0;
    border-bottom: 1px solid var(--bs-gray-200);
}


.yourCartTableOuter table th:nth-child(2),
.yourCartTableOuter table td:nth-child(2) {
    width: 120px;
}

.yourCartTableOuter table th:last-child,
.yourCartTableOuter table td:last-child {
    text-align: right;
}

.yourCartTableOuter table>tr+tr td {
    border-top: 2px dotted rgba(0, 48, 87, .2);
}

.yourCartTableOuter table tr th,
.yourCartTableOuter table tr td {
    background-color: transparent;
    color: var(--color);
}

.yourCartTableOuter table tr td {
    position: relative;
    padding: 15px 5px;
    /* color: var(--body-color); */
    vertical-align: middle;
}

.removeYourCart img {
    width: 24px;
    max-width: initial;
}

.tabletxt {
    width: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
}

.tabletxt__img {
    width: 50px;
    margin: 0 10px 0 0;
}

.orderHolder .tabletxtOrderDetail {
    --widthx: 150px
}

.orderHolder .tabletxtOrderDetail .tabletxt__img {
    flex: 0 0 var(--widthx);
    max-width: var(--widthx);
    width: auto;
}

.orderHolder .tabletxtOrderDetail .tabletxt__content {
    width: auto;
    flex: 0 0 calc(100% - var(--widthx));
    max-width: calc(100% - var(--widthx));
    padding-left: 10px;
}

.tabletxt__img img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border: #eee 1px solid;
    padding: 1px;
    border-radius: 0;
}

.tabletxt__content {
    width: calc(100% - 100px);
}

.tabletxt__content h4 {
    /* font-size: 13px; */
    margin: 0 0 5px;
    /* font-weight: var(--semiBold); */
    letter-spacing: normal;
}

.tabletxt__content p {
    margin: 0;
    /* font-weight: var(--semiBold); */
    font-size: inherit;
}

.tabletxt__content p span {
    font-weight: normal;
    font-size: inherit;
}

.yourCartquantityOuter {
    display: inline-block;
    text-align: center;
}
 

.yourCartquantityOuter .quantity {
    border: 1px solid #d3d4d3;
    border-radius: 2px;
}

.yourCartquantityOuter .quantity input {
    border: 0;
    background: transparent;
    color: #323232;
    width: 65px;
    text-align: center;
    padding: 5px;
}

.yourCartquantityOuter .quantity input:focus {
    outline: 0;
}

.removeYourCart {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    /* color: var(--bs-red); */
    margin: 0 10px;
    fill: var(--color);
}


/*Your Cart Sidebar*/


.orderSummaryPart {
    /* background-color: #f2f2f2; */
    border-bottom: #f2f2f2 1px solid;
    padding: 32px 30px;
    position: sticky;
    top: 90px;
    /* border-radius: var(--button_radius); */
}

.orderSummaryPart h3 {
    /* font-size: 17px; */
    /* font-weight: var(--semiBold); */
    /* color: var(--body-color); */
    margin-bottom: 15px;
    border-bottom: 1px solid var(--bs-gray-200);
    padding-bottom: 6px;
}

.orderSummaryPart h5 {
    width: 100%;
    display: inline-block;
    /* font-size: 13px; */
    margin: 0;
    /* font-weight: var(--regular); */
}

.orderSummaryPart h5+h5 {
    margin-top: 10px;
}

.orderSummaryPart h5 span {
    /* font-size: 15px; */
    float: right;
    /* font-weight: var(--semiBold); */
}

.orderSummaryPart .subtotalPart {
    border-top: 2px dotted rgba(0, 48, 87, .2);
    padding-top: 20px;
    margin-top: 20px;
}

.orderSummaryPart .subtotalPart span {
    font-size: 20px;
}

.cartCheckoutButton {
    margin-top: 30px;
}



@media only screen and (max-width: 991px) {
    .quantityholder input.num-box {
        width: 50px;
    }

    .yourCartTable .table-responsive {
        padding: 0;
        overflow: visible;
    }

    .yourCartTable .table-responsive table.table,
    .yourCartTable .table-responsive table.table tr,
    .yourCartTable .table-responsive table.table td,
    .yourCartTable .table-responsive table.table tbody {
        display: block;

    }

    .yourCartTable .table-responsive table.table td {
        width: 100%;
        text-align: right;
        position: static;
        padding: 10px 0;
    }

    .yourCartTable .table-responsive table.table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .yourCartTable .table-responsive table.table td[data-label="removebtn"]:before {
        display: none;
    }

    .yourCartTable .table-responsive table.table td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }

    .tabletxt__img {
        width: 50px;
    }

    .tabletxt__content {
        width: 100%;
    }

    .tabletxt {
        flex-direction: row-reverse;
        align-items: flex-start;
        width: 100%;
        min-width: auto;
        text-align: left;
        padding: 10px 0 0;
    }

    .yourCartTable .table-responsive table.table tr {
        background: #f2f2f2;
        margin-bottom: 20px;
        position: relative;
    }

    .removeYourCart {
        /* position: absolute;
        top: -10px;
        right: -10px;
        z-index: 1;
        background: #fff;
        box-shadow: -1px 4px 10px 0 rgb(0 0 0 / 25%);
        border-radius: 50%; */
    }

    .yourCartTableOuter table tr td {
        padding: 10px 7px;
    }

    .yourCartTable .table>:not(caption)>*>* {
        background-color: var(--bg);
    }

    .orderSummaryPart {
        padding: 20px;
    }
}

/* cart page  */