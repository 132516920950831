:root{
    --white: #fff;
    --black: #000;
}

* {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 5px;
    outline: none;
}

::-webkit-scrollbar-thumb {
    background: #bcbcbc;
    border-radius: 40px;
}

::-webkit-scrollbar-track {
    background: #FAFAFA;
}

*:focus {
    outline: none;
}

.form-select-outer {
    position: relative;
}

.form-select-outer:after {
    background-image: url(../../../Assets/images/bx-chevron-down.svg);
    background-position: calc(100% - 7px) 50%;
    background-repeat: no-repeat;
    background-size: 25px;
    content: "";
    height: 30px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 30px;
    z-index: 99;
}

.customizer_outer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    background-color: var(--white);
}

.layout_sidebar {
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.1) 2px 0 5px;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
}

.layout_sidebar_container {
    flex: 1 0 auto;
    border: #eee 1px solid;
    border-width: 1px 0;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 70px);
    /* background-color: #f9f9f9; */
    display: flex;
    flex-direction: column;
    position: relative;
}

.layout_sidebar_sections {
    flex: 1 0 auto;
    padding-bottom: 30px;
    max-height: calc(100% - 40px);
    overflow: hidden;
    overflow-y: auto;
}

.themesetting_btn {
    position: sticky !important;
    bottom: 0;
    background-color: #fff !important;
}

.layout_container {
    width: calc(100% - 300px);
    justify-self: flex-end;
    background: var(--buttonColor);
    color: var(--buttonText);
    height: calc(100% - 72px);
    max-height: calc(100vh - 72px);
    position: relative;
    z-index: -1;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
}

.layout_container.template_main {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 3px;
    padding: 0;
}

.customizer_wrapper {
    display: flex;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
}

/* .customizer_wrapper > div {
    display: table-cell;
    vertical-align: top;
} */
.edit_selected_image {
    position: relative;
    cursor: pointer;
}

.edit_selected_image svg {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px;
    background-color: #fff;
    padding: 8px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
}

input[type="file"] {
    cursor: pointer;
}

.layout_card {
    /* height: 100%; */
    margin: auto;
    /* border: #eee 1px solid; */
}

.layout_card iframe {
    /* position: absolute;
    height: 100%;
    width: 100%; */
    border: 0;
}

ul[variant="masonry"] {
    padding-left: 0;
}

ul[variant="fixed"] {
    padding-left: 0;
    column-count: auto !important;
    column-gap: inherit !important;
}

ul[variant="fixed"] a.gallery_image_link {
    height: 100%;
}

a.gallery_image_link img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.makeStyles-colorpickerButton-2 {
    margin: 3px !important;
}

.uploader_outer .post_uploader,
.uploader_outer.active .pre_uploader {
    display: none;
}

.uploader_outer.active .post_uploader {
    display: block;
}

.media_image {
    min-height: 6.47rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
}

.media_image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 3px;
}

.active_item {
    background-color: #e1e1e1;
}

.main_slider .slick-arrow {
    display: none !important;
}

.hero_slider_outer,
.product_slider_outer {
    position: relative;
}

.hero_slider_outer .slick-arrows {
    color: var(--buttonColor) !important;
}

.slick-arrows-prev,
.slick-arrows-next {
    position: absolute !important;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9;
    cursor: pointer;
}

.slick-arrows.slick-arrows-next {
    left: auto;
    right: 0;
}

.main_slider .slick-prev {
    left: 25px;
    z-index: 9;
}

.product_slider_outer .slick-arrows.slick-arrows-next {
    right: -35px;
    left: auto;
}

.product_slider_outer .slick-arrows.slick-arrows-prev {
    left: -35px;
}

.main_slider .slick-dots {
    bottom: 25px;
}

.main_slider .slick-dots li {
    width: 30px;
}

.main_slider .slick-dots li button:before {
    content: "";
    background-color: var(--buttonColor);
    width: 100%;
    height: 6px;
}

.hero_slider_inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    text-decoration: none;
    vertical-align: top;
}

.bg_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    /* background-color: #000;
    opacity: 0.3; */
    pointer-events: none;
}

.hero_slider_content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin: auto;
    text-align: center;
}

.bg_overlay+.container .hero_slider_content *:not(.btn) {
    color: var(--overlay_text_color);
}

.hero_slider_content .MuiButton-containedWhite {
    color: #000 !important;
    background-color: #fff !important;
}

a.hero_slider_inner .hero_slider_content {
    max-width: 60%;
}

.hero_slider_content.left {
    justify-content: flex-start;
    margin: 0;
    text-align: left;
}

.hero_slider_content.right {
    justify-content: flex-end;
    margin: 0;
    margin-left: auto;
    text-align: right;
}

.hero_slider_inner.top {
    align-items: flex-start;
}

.hero_slider_inner.bottom {
    align-items: flex-end;
}

.img-fluid {
    max-width: 100%;
}

.customTextEditor {
    min-height: 120px;
    max-height: 200px;
    padding: 0 10px 10px;
}

.customTextEditor .public-DraftStyleDefault-block {
    margin: 0px 0;
}

.customTextEditorTools {
    padding: 5px 2px 0;
}

.full-img {
    width: 100%;
    vertical-align: middle;
}

.active_items>.MuiBox-root>*:not(.list_items) {
    display: none;
}
.list_items{
    /* max-height: 300px;
    overflow: hidden;
    overflow-y: auto; */
}

.rdw-link-wrapper .rdw-link-modal {
    left: auto;
    right: 0;
    height: auto;
}

.map_address_block {
    background-color: #fff;
    padding: 20px 20px 30px;
    border-radius: 10px;
    text-align: center;
}

.map_address_outer {
    position: absolute;
    top: 40px;
    left: 25vw;
    width: 350px;
    transform: translateX(-50%);
}

.map_address_outer.vertical_bottom {
    top: auto;
    bottom: 40px;
}

.map_address_outer.vertical_center {
    top: 50%;
    transform: translate(-50%, -50%);
}

.map_address_outer.horizontal_right {
    left: auto;
    right: 0;
}

.map_address_outer.horizontal_center {
    left: 50%;
}


.header_outer_block {
    padding: 8px !important;
}

.testimonial_slider_outer {
    position: relative;
    text-align: center;
    padding: 40px;
}

.client_image {
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    border: #c1c1c1 2px solid;
}

.client_image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.testimonial_slider_content {
    max-width: 700px;
    margin: 20px auto;
}

.testimonial_slider_inner {
    padding: 20px;
}
.testimonial_slider_inner .quote svg *{
    fill: var(--color);
}
.testimonial_slider_outer .slick-dots li button:before {
    content: "";
    background-color: var(--color);
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.layout_card *:not(.MuiTypography-root, .MuiButtonBase-root) {
    /* font-family: inherit;
    color: inherit; */
}

/* .layout_card *{
    line-height: 1.6;
 } */
.item_drag,
.item_drag>div {
    width: 100%;
}

.video_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
    z-index: 9;
    pointer-events: none;
}

.list_edit_input input {
    padding: 5px 10px;
    font-size: 14px;
}

.customizer_wrapper.show_preview .layout_sidebar {
    display: none;
}

.customizer_wrapper.show_preview .layout_container {
    width: 100%;
    padding: 0;
}


/* layout 2 */
.layout2 .productHolder__swapper {
    justify-content: space-between !important;
    margin-top: 10px;
}

.layout2 .productHolder__swapper .productHolder__price {
    margin: 0 0 10px;
}

.template_main .layout2 .productHolder__btnHolder .btn.btn-sm {
    /* padding: 0.4rem 1rem; */
    font-size: 14px;
}

.productHolder__img,
.productHolder__img_holder {
    overflow: hidden;
    /* border: #f1f1f1 1px solid; */
}

.productHolder__img img {
    transition: ease 0.5s;
}

.productHolder__img:hover img {
    transform: scale(1.05);
}

.productHolder__img a {
    display: block;
}

.layout3 .productHolder__price {
    padding: 10px 0;
}

.layout4 .productHolder__btnHolder {
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--buttonColor);
    color: var(--buttonText);
    opacity: 0;
    transition: ease 0.3s;
    visibility: hidden;
    -webkit-transform: translate(-50%, 105%);
    transform: translate(-50%, 105%);
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: var(--button_radius);

}

.layout4 .productHolder:hover .productHolder__btnHolder {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

span.add_to_cart_btn_text {
    position: absolute;
    left: 110%;
    font-size: 15px;
}

.layout4 .productHolder:hover .productHolder__btnHolder:hover span.add_to_cart_btn_text {
    transition: ease-out 0.3s;
    position: relative;
    left: 0;
    margin: 0 10px;
}

.add_to_cart_btn svg {
    width: 24px;
}

.productHolder__title a {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    text-decoration: none;
}

.layout1 .productHolder__cntnt {
    text-align: center;
}

/* 
.layout2 .productHolder__cntnt {
    text-align: left !important;
} */

.productHolder__cntnt {
    padding: 10px 0 20px;
}

.template_main {
    width: 100%;
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    font-weight: var(--body_font_weight);
    color: var(--color);
    background-color: var(--bg);
    display: flex;
    flex-direction: column;
}

.template_main h1,
.template_main h2,
.template_main h3,
.template_main h4,
.template_main h5,
.template_main h6 {
    font-family: var(--heading_font) !important;
    font-weight: var(--heading_font_weight) !important;
    color: var(--heading_color);
}

/* .template_main h1 {
    font-size: calc(var(--heading_size) * 2);
}

.template_main h2 {
    font-size: calc(var(--heading_size) * 1.5);
}

.template_main h3 {
    font-size: var(--heading_size);
}

.template_main h4 {
    font-size: calc(var(--heading_size) / 1.2);
}

.template_main h5 {
    font-size: calc(var(--heading_size) / 1.5);
}

.template_main h6 {
    font-size: calc(var(--heading_size) / 1.6);
} */

.template_main h1 {
    font-size: var(--heading_size);
}

.template_main h2 {
    font-size: calc(var(--heading_size) / 1.4);
}

.template_main h3 {
    font-size: calc(var(--heading_size) / 1.6);
}

.template_main h4 {
    font-size: calc(var(--heading_size) / 1.8);
}

.template_main h5 {
    font-size: calc(var(--heading_size) / 2);
}

.template_main h6 {
    font-size: calc(var(--heading_size) / 2.5);
}

.template_main h1.singleProduct__title {
    /* font-size: calc(var(--heading_size) * 1.2); */
}


.template_main a {
    text-decoration: none;
}

.template_main button:not(.btn) {
    background-color: inherit;
    color: inherit;
    border: 0;
}


.template_main .btn {
    font-size: var(--fontSize);
    font-weight: var(--button_font_weight);
    border-radius: 0;
    background-color: var(--buttonColor);
    border-color: var(--button_border_color);
    color: var(--buttonText);
    border-radius: var(--button_radius);
    padding: var(--button_spacing_y) var(--button_spacing_x);
}

.template_main .btn.btn-sm {
    /* padding: 0.4rem 2rem; */
}

.template_main .btn.btn-danger {
    background-color: #ff0000 !important;
    border-color: #ff0000 !important;
    color: #fff !important;
}

.template_main .btn.btn-lg {
    padding: 1.5rem 3.5rem;
}

.template_main .btn:hover {
    background-color: var(--buttonHover);
    border-color: var(--button_hover_border_color);
    color: var(--buttonHoverText);
}

.template_main .btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}


.template_main .btn.btn-outline-primary {
    border-color: var(--button_border_color);
    color: var(--button_border_color);
    background: transparent;
}

.template_main .btn.btn-outline-primary:hover {
    color: var(--buttonText);
    background: var(--buttonColor);
}

.template_main .newsletter .form-control {
    border-radius: 0;
}

.template_main .newsletter .form-control:focus-visible,
.template_main .newsletter .form-control:focus-within {
    box-shadow: none;
    outline: 0;
}

.template_main del {
    /* color: #b6b6b6 !important; */
    color: var(--heading_color) !important;
    opacity: 0.5;
}
.template_main .form-check-input {
    background-color: transparent;
}
.template_main .form-check-input:checked {
    background-color: var(--buttonColor);
    border-color: var(--buttonColor);
}

.template_main .form-check-input:focus {
    box-shadow: none;
}


.largeSlider {
    display: inline-block;
    width: 100%;
}

.largeSlider,
.mapImgHolder {
    height: auto;
    vertical-align: middle;
}

.mapImgHolder {
    /* padding-bottom: 50%; */
}

.text_over_image:not(.show_content) .hero_slider_inner {
    display: none;
}

.largeSlider>.full-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.mapImgHolder>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.mapImgHolder>iframe {
    width: 100%;
    height: 100%;
    display: block;
}

.mapImgHolder {
    /* min-height: 500px; */
}

.layout_card_inner {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.blogpostBox {
    margin-bottom: 20px;
}

.product_slider_outer .slick-arrows {
    color: var(--buttonColor);
}

#InfiniteScroll_overflow {
    overflow: hidden;
    overflow-y: auto;
}

.custom_input_group.input-group .form-control option {
    background: var(--bg);
    color: var(--color);
}

.text-red {
    color: #ff0000 !important;
}

.category_list_block {
    position: relative;
    z-index: 0;
    display: block;
}

/* .category_list_title.layout1 {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: ease 0.3s;
    padding: 20px;
} */

.category_list_block .bg_overlay {
    display: none;
}

/* .category_list_block.layout1 .bg_overlay {
    display: block;
}

.category_list_title.layout1 h5 {
    color: inherit;
} */

.category_list_image {
    overflow: hidden;
    position: relative;
    z-index: -1;
}

.category_list_image img {
    transition: ease 0.5s;
}

.category_list_block:hover .category_list_image img {
    transform: scale(1.05);
}

.category_list_block:hover .category_list_title {
    opacity: 1;
    visibility: visible;
    color: var(--overlay_text_color);
}

.category_list_block .category_list_title.layout2 {
    padding: 10px 10px 0;
    text-align: center;
}

.category_list_block.layout3 .category_list_image {
    overflow: hidden;
    border-radius: 5px;
}

.category_list_title.layout3 {
    text-align: center;
    padding: 10px 5px 0;
}

.cart_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}

.slick-slider.main_slider {
    display: none;
}

.slick-slider.main_slider.slick-initialized {
    display: block;
}

.infinite-scroll-component {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.pagination_loader {
    padding: 15px;
    text-align: center;
}

.testimonial_slider button.slick-arrow {
    display: none !important;
}

.gallery_image_caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    transition: ease 0.3s;
    transform: translateY(110%);
}

.gallery_image_link {
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%;
}

.gallery_image_link:hover .gallery_image_caption {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.gallery_image_caption .bg_overlay {
    z-index: -1;
}

.video_section {
    position: relative;
    padding-bottom: 56.3%;
}

.video_section video,
.video_section iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.video_section .bg_overlay {
    z-index: 1;
}

.content_block_outer {
    padding: 20px 0;
}

.layout3 .productHolder__img {
    border-radius: 10px;
}

.layout3 .productHolder {
    border: 1px solid var(--buttonColor);
    padding: 10px;
    border-radius: 10px;
}

.layout3 .productHolder__cntnt {
    padding-bottom: 0;
}

.productHolder__btnHolder .btn {
    border-radius: var(--button_radius);
    white-space: nowrap;
}

.linear_progress {
    display: block;
    background-color: #e6e6e6;
    width: 100%;
    height: 4px;
    position: relative;
    overflow: hidden;
}

.linear_progress_bar {
    background-color: var(--color);
    width: 20%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: bar linear infinite 2s;
}

@keyframes bar {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(500%);
    }
}

.no_product_found {
    text-align: center;
    padding: 40px;
}

.gallery_image_link {
    overflow: hidden;
}

.gallery_image_link img {
    transform: scale(1);
    transition: ease 0.5s;
}

.gallery_image_link:hover img {
    transform: scale(1.02);
}

.testimonial_slider_outer.layout2 .testimonial_slider_inner {
    /* box-shadow: rgba(0, 0, 0, 0.08) 0 0 21px;
    border-radius: 6px;
    background: #fff; */
}

.testimonial_slider_outer.layout2 .slick-slide {
    padding: 20px 15px;
}

.testimonial_slider_outer.layout2 {
    padding: 0;
}

.testimonial_slider_outer.layout2 .testimonial_slider_content {
    max-width: 100%;
}

.testimonial_slider_outer.layout2 .client_image {
    margin: 0 10px 0 0;
    width: 50px;
    height: 50px;
}

.testimonial_arrows {
    display: flex;
    align-items: center;
}

.testimonial_arrows .slick-arrows {
    position: static !important;
}

.testimonial_arrows .slick-arrows svg {
    fill: var(--color);
    font-size: 22px;
}

p:last-of-type {
    margin-bottom: 0;
}

.active_items li.item_drag:not(.active_item) {
    opacity: 0.2;
    pointer-events: none;
}

.layout4 .productHolder__swapper {
    justify-content: space-between !important;
    margin-top: 10px;
}

.iiz__btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px;
}

.iiz__hint:before {
    width: 15px;
    height: 15px;
}



.quantityholder {
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.productSliderCntnt__qnttyBtn .quantityholder.numbers-row {
    margin: 10px 8px 10px 0;
}

.quantityholder input[type=number]::-webkit-inner-spin-button,
.quantityholder input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.quantityholder input[type=number] {
    -moz-appearance: textfield;
}

.quantityholder input.num-box {
    text-align: center;
    border: none;
    border: solid var(--bs-gray-200);
    border-width: 1px 0;
    margin: 0px;
    width: 60px;
    height: 36px;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0;
    box-shadow: none;
}

.quantityholder button.valueButton {
    display: block;
    padding: 0;
    width: 22px;
    height: 37px;
    line-height: 33px;
    font-size: 20px;
    text-align: center;
    text-align-last: center;
    margin: 0px;
    border: none;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 36px;
    width: 30px;
    background: var(--buttonColor);
    color: var(--buttonText);
}

.quantityholder button.valueButton.inc {
    font-size: 24px;
}

.quantityholder button.valueButton.disabled,
.removeYourCart.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.quantityholder button.valueButton img, .quantityholder button.valueButton svg {
    width: 18px;
}

.img_delete_editor {
    position: absolute;
    top: 0;
    right: 0;
}

.layout3 .productHolder__btnHolder {
    width: 100%;
}

.testimonial_slider_outer .slick-arrows {
    min-width: inherit;
    padding: 0;
}

.template_main .singleProduct__titlesmall {
    white-space: normal !important;
}

.template_main {
    word-break: break-word;
}

.iframeMap {
    /* padding-bottom: 25%; */
}

h1.categorysidebar__title {
    font-size: 30px;
}

.is_hover .slick-slide > div,
.is_hover .brand_list_image {
    overflow: hidden;
}
.brand_block .slick-slide > div {
    padding: 10px;
}

.is_hover .brand_list_image img {
    transition: ease 0.3s;
    transform: scale(1);
}

.is_hover .brand_list_image:hover img {
    transform: scale(1.1);
}

.product_slider_outer .slick-arrows[disabled] {
    pointer-events: none;
    opacity: 0.2;
}
.bottom_bar {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 10px 15px;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 10px;
    align-items: center;
}

.bottom_bar p {
    color: #000 !important;
}

button.custom_btn {
    padding: 8px 15px;
    background: #f1592a !important;
    color: #fff !important;
    font-size: 12px;
    border-radius: 5px;
    border: transparent 1px solid !important;
}

button.custom_btn.custom_btn-outline-primary {
    background: transparent !important;
    border-color: red !important;
    color: red !important;
}

.bottom_bar.hide {
    display: none;
}

.map_address_block * {
    color: #000 !important;
}

.st-0 {
    padding-top: 0px;
}

.st-1 {
    padding-top: 10px;
}

.st-1 {
    padding-top: 10px;
}

.st-2 {
    padding-top: 20px;
}

.st-3 {
    padding-top: 30px;
}

.st-4 {
    padding-top: 40px;
}

.st-5 {
    padding-top: 50px;
}

.st-6 {
    padding-top: 60px;
}

.st-7 {
    padding-top: 70px;
}

.st-8 {
    padding-top: 80px;
}

.st-9 {
    padding-top: 90px;
}

.st-10 {
    padding-top: 100px;
}

.sb-0 {
    padding-bottom: 0px;
}

.sb-1 {
    padding-bottom: 10px;
}

.sb-2 {
    padding-bottom: 20px;
}

.sb-3 {
    padding-bottom: 30px;
}

.sb-4 {
    padding-bottom: 40px;
}

.sb-5 {
    padding-bottom: 50px;
}

.sb-6 {
    padding-bottom: 60px;
}

.sb-7 {
    padding-bottom: 70px;
}

.sb-8 {
    padding-bottom: 80px;
}

.sb-9 {
    padding-bottom: 90px;
}

.sb-10 {
    padding-bottom: 100px;
}

.productHolder__img {
    position: relative;
}
.edit_sections{
    display: none;
}

@media screen and (min-width:992px) {

    .site_header.layout2+.category_page {
        padding-top: 80px !important;
    }

    .site_header.layout2+.product_page,
    .site_header.layout2+.my_account_section,
    .site_header.layout2+.shoppingCart {
        padding-top: 120px !important;
    }

    /*  */
    .layout1 .productHolder__swapper {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
        margin: 0 auto;
        -webkit-transition: .15s ease-out;
        -o-transition: .15s ease-out;
        transition: .15s ease-out;
        width: 189px;
        height: calc(var(--heading_size) + 30px);
        margin-top: 5px;
    }

    .layout1 .productHolder__swapper .productHolder__price {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: -webkit-transform .4s .1s ease;
        transition: -webkit-transform .4s .1s ease;
        -o-transition: transform .4s .1s ease;
        transition: transform .4s .1s ease;
        transition: transform .4s .1s ease, -webkit-transform .4s .1s ease;
        width: 189px;
        position: absolute;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        text-align: center;

    }

    .layout1 .productHolder__swapper .productHolder__btnHolder {
        width: 100%;
        -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform .5s 0s ease;
        transition: -webkit-transform .5s 0s ease;
        -o-transition: transform .5s 0s ease;
        transition: transform .5s 0s ease;
        transition: transform .5s 0s ease, -webkit-transform .5s 0s ease;
        overflow: hidden;
        position: absolute;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }

    .layout1 .productHolder:hover .productHolder__swapper>.productHolder__price {
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);

    }

    .layout1 .productHolder:hover .productHolder__swapper>.productHolder__btnHolder {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@media screen and (max-width:991px) {
    .layout_container.template_main .navbarSmall {
        display: none;
    }
    .layout2 .productHolder__swapper {
        margin-top: 10px;
    }

    .layout1 .productHolder__swapper {
        flex-direction: column;
        align-items: center !important;
    }

    .layout1 .productHolder__swapper .productHolder__price {
        margin-bottom: 10px;
    }

    .layout1 .productHolder__swapper {
        padding-top: 10px;
    }

    .layout2 .productHolder__cntnt {
        text-align: center !important;
    }

    .layout2 .productHolder__swapper {
        align-items: center !important;
    }
    .cart_backdrop{
        background-color: var(--overlay_bg);
        opacity: var(--overlay_opacity);
    }
}


@media screen and (min-width: 750px) {

    .largeSlider,
    .mapImgHolder {
        height: auto;
        min-height: 100px;
    }
}

@media screen and (max-width: 1250px) {
    .product_slider_outer .slick-arrows.slick-arrows-next {
        right: 0;
    }

    .product_slider_outer .slick-arrows.slick-arrows-prev {
        left: 0;
    }
}

@media screen and (max-width: 991px) {

    .layout4 .productHolder__btnHolder {
        display: none;
    }

    /* .template_main h1 {
        font-size: calc(var(--heading_size) * 1.5);
    }

    .template_main h2 {
        font-size: calc(var(--heading_size) * 1.2);
    }

    .template_main h3 {
        font-size: calc(var(--heading_size));
    }

    .template_main h4 {
        font-size: calc(var(--heading_size) / 1.2);
    }

    .template_main h5 {
        font-size: calc(var(--heading_size) / 1.4);
    }

    .template_main h6 {
        font-size: calc(var(--heading_size) / 1.5);
    } */

    .product_slider_outer .slick-arrows.slick-arrows-prev {
        left: -15px;
    }

    .product_slider_outer .slick-arrows.slick-arrows-next {
        right: -15px;
    }

    .product_slider_outer .slick-arrows {
        padding: 0;
    }

    .product_slider_outer .slick-arrows svg {
        width: 22px;
    }

    .template_main {
        font-size: 14px;
    }

    .st-5 {
        padding-top: 40px;
    }

    .st-6 {
        padding-top: 50px;
    }

    .st-7 {
        padding-top: 60px;
    }

    .st-8 {
        padding-top: 70px;
    }

    .st-9 {
        padding-top: 80px;
    }

    .st-10 {
        padding-top: 90px;
    }

    .sb-5 {
        padding-bottom: 40px;
    }

    .sb-6 {
        padding-bottom: 50px;
    }

    .sb-7 {
        padding-bottom: 60px;
    }

    .sb-8 {
        padding-bottom: 70px;
    }

    .sb-9 {
        padding-bottom: 80px;
    }

    .sb-10 {
        padding-bottom: 90px;
    }

}

@media screen and (max-width: 900px) {
    .layout_sidebar {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: 99;
        max-height: 50px;
        border-radius: 20px 20px 0 0;
        right: 0;
        box-shadow: rgba(0, 0, 0, 0.2) 0px -4px 10px;
        height: auto;
    }
    .layout_sidebar_container {
        border: 0;
        max-height: none;
        overflow: hidden;
        min-height: 60dvh;
    }
    .activeBlock_outer {
        max-height: calc(100dvh - 160px);
        overflow: hidden;
        overflow-y: auto;
    }
    .layout_sidebar_sections{
        overflow: hidden;
    }
    .layout_sidebar.showSections {
        max-height: none;
        justify-content: flex-end;
    }
    .layout_sidebar.showSections .layout_sidebar_sections {
        overflow-y: auto;
    }
    div#freshworks-container iframe {
        bottom: 40px !important;
        right: 0 !important;
    }
    .edit_sections{
        display: block;
    }
    .edit_sections button{
        position: relative;
    }
    .edit_sections button:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #ddd;
        width: 50px;
        height: 4px;
    }

}
@media screen and (max-width: 767px) {
    .map_address_outer {
        left: 15px;
        transform: none;
    }

    .map_address_outer.horizontal_left {
        left: 50%;
    }

    .map_address_outer.horizontal_right {
        left: auto;
        right: 50%;
    }

    .map_address_outer.horizontal_center {
        transform: translateX(-50%);
    }

    .map_address_outer.horizontal_center.vertical_center {
        transform: translateX(-50%, -50%);
    }

    .iframeMap {
        padding-bottom: 50%;
    }

    .hero_slider_inner.md-top {
        align-items: flex-start !important;
        padding-top: 30px;
    }

    .hero_slider_inner.md-bottom {
        align-items: flex-end !important;
        padding-bottom: 30px;
    }

    .st-5 {
        padding-top: 30px;
    }

    .st-6 {
        padding-top: 40px;
    }

    .st-7 {
        padding-top: 50px;
    }

    .st-8 {
        padding-top: 60px;
    }

    .st-9 {
        padding-top: 70px;
    }

    .st-10 {
        padding-top: 80px;
    }

    .sb-5 {
        padding-bottom: 30px;
    }

    .sb-6 {
        padding-bottom: 40px;
    }

    .sb-7 {
        padding-bottom: 50px;
    }

    .sb-8 {
        padding-bottom: 60px;
    }

    .sb-9 {
        padding-bottom: 70px;
    }

    .sb-10 {
        padding-bottom: 80px;
    }
}

@media screen and (max-width: 575px) {
    .template_main h1 {
        font-size: 7vw;
    }

    .template_main h2 {
        font-size: 6vw;
    }

    .template_main h3 {
        font-size: 5.5vw;
    }

    .template_main h4 {
        font-size: 5vw;
    }

    .template_main h5 {
        font-size: 4vw;
    }

    .template_main h6 {
        font-size: 3.5vw;
    }

    .template_main .btn.btn-sm {
        padding: 0.4rem 1rem;
        font-size: 14px;
    }

    .main_slider .slick-dots {
        bottom: 10px;
    }

    .map_address_block {
        padding: 20px 15px;
    }

    /* .template_main h1 {
        font-size: calc(var(--heading_size));
    }
    .template_main h1 {
        font-size: calc(var(--heading_size));
    } */

    .testimonial_slider_outer {
        padding: 10px;
    }

    .product_slider_outer .slick-arrows {
        display: none;
    }

    .template_main h1.singleProduct__title {
        font-size: 7vw;
        margin-bottom: 10px;
    }


    /* .template_main h5 {
        font-size: calc(var(--heading_size) / 1.5);
    }

    .template_main h6 {
        font-size: calc(var(--heading_size) / 1.7);
    } */

    .layout2 .productHolder__swapper {
        flex-direction: column;
        /* align-items: flex-start !important; */
        margin-top: 10px;
    }

    .layout2 .productHolder__swapper .productHolder__btnHolder {
        margin-top: 10px;
    }

    .template_main .layout2 .productHolder__btnHolder .btn.btn-sm {
        font-size: 12px;
    }

    ul[variant="masonry"],
    ul[variant="fixed"] {
        column-count: 2 !important
    }

    .testimonial_slider .slick-dots {
        position: static;
    }

    .testimonial_header {
        display: flex;
        justify-content: space-between;
    }

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        --bs-gutter-x: 2rem;
    }

    .hero_slider_content {
        /* max-width: 70%; */
        margin: 0;
    }

    .quantityholder input.num-box {
        height: 30px;
        width: 30px;
    }

    .quantityholder button.valueButton {
        line-height: 22px;
        height: 30px;
    }

    .layout3 .productHolder__swapper {
        margin-top: 0px;
        flex-direction: column;
        /* align-items: flex-start !important; */
    }

    .testimonial_slider_outer .slick-dots li {
        width: 10px;
    }

    .hero_slider_content.left {
        padding: 0 20px;
    }

    .text_over_image .hero_slider_content p br {
        display: none;
    }

    .hero_slider_content.right {
        padding-right: 20px;
    }

    .map_address_outer {
        display: none;
    }

    .testimonial_slider_outer .slick-arrows {
        width: 10px;
    }

    h1.categorysidebar__title {
        font-size: 24px;
    }

    .bottom_bar {
        flex-direction: column;
    }

    .bottom_bar_btn {
        margin-top: 10px;
    }


    .st-5 {
        padding-top: 20px;
    }

    .st-6 {
        padding-top: 30px;
    }

    .st-7 {
        padding-top: 40px;
    }

    .st-8 {
        padding-top: 50px;
    }

    .st-9 {
        padding-top: 60px;
    }

    .st-10 {
        padding-top: 70px;
    }

    .sb-5 {
        padding-bottom: 20px;
    }

    .sb-6 {
        padding-bottom: 30px;
    }

    .sb-7 {
        padding-bottom: 40px;
    }

    .sb-8 {
        padding-bottom: 50px;
    }

    .sb-9 {
        padding-bottom: 60px;
    }

    .sb-10 {
        padding-bottom: 70px;
    }

    .hero_slider_outer .slick-arrows {
        padding: 0;
    }

    .hero_slider_content.sm-center {
        text-align: center !important;
        justify-content: center !important;
    }

    .hero_slider_content.sm-right {
        text-align: left !important;
        justify-content: flex-end !important;
    }
}

@media screen and (max-width:380px) {
    .template_main .btn.btn-sm {
        font-size: 12px;
    }

}

@media screen and (min-width:575px) {
    .col-sm-15 {
        flex: 0 0 auto;
        width: 20%;
    }

}

@media screen and (min-width:767px) {
    .col-md-15 {
        flex: 0 0 auto;
        width: 20%;
    }

    .col-md-15 {
        flex: 0 0 auto;
        width: 20%;
    }

}

@media screen and (min-width:992px) {
    .col-lg-15 {
        flex: 0 0 auto;
        width: 20%;
    }

}

@media screen and (min-width:575px) {

    .hero_slider_content,
    .template_main h1,
    .template_main h2,
    .template_main h3,
    .template_main h4,
    .template_main h5,
    .template_main h6,
    .template_main p {
        white-space: pre-wrap;
    }

}

.ViewOrderDeliveryBox .accordion-item {
    --bs-accordion-bg: transparent;
}

@media (prefers-color-scheme: dark) {
    :root {
      --white: #252525;
      --black: #fff;
    }
    .ViewOrderDeliveryBox .accordion-item {
        --bs-accordion-color: #fff;
    }
    .accordion-button::after{
        filter: invert(1)
    }
    .xIcon circle{
        fill: #fff;
    }
    .xIcon path{
        fill: #000;
    }
}