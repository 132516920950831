.MuiInputLabel-outlined {
  background: var(--white); 
  padding: 0 5px !important;
}

.MuiAlert-outlinedError {
  background-color: #eedada !important;
}

input:-webkit-autofill {
  background-color: var(--white) !important;
  -webkit-box-shadow: 0 0 0 1000px var(--white) inset;
  -webkit-transition-property: var(--white) !important;
  background-clip: content-box;
}

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
select:-webkit-autofill,
select:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px var(--white) inset !important;
  -webkit-text-fill-color: #535353;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiDialogContent-root {
  overflow: hidden !important;
  overflow-y: auto !important;
}

.float-top-left {
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: rgb(0, 0, 0, 0.5) 2px 2px 1px;
  border-radius: 50%;
  background: #fff;
}

.float-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
}


.MuiInputBase-inputSizeSmall {
  font-size: 14px !important;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiInputLabel-formControl.MuiInputLabel-sizeSmall {
  line-height: 1.1em;
}

.MuiDataGrid-columnHeaderTitleContainer {
  padding-left: 0 !important;
}

.react-tel-input .flag-dropdown {
  background-color: #fff !important;
}

.MuiLoadingButton-loadingIndicator {
  /* position: static !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  transform: none !important; */
}

.MuiButton-sizeLarge.MuiLoadingButton-loading {
  min-width: 120px !important;
}

.MuiButton-sizeMedium.MuiLoadingButton-loading {
  min-width: 100px !important;
}

.video_preview_product {
  cursor: pointer;
}

.video_preview_product * {
  pointer-events: none;
}

.variant_list .basicAnchor.active .MuiListItemButton-root {
  background: #f9f9f9 !important;
}

.variant_list .basicAnchor.active * {
  font-weight: 700;
}

.Mui-error .MuiOutlinedInput-notchedOutline, input.form-control[inputclass="error"] {
  border-color: red;
  background: rgba(255, 0, 0, 0.05);
}

/* .video_preview_product {
  position: relative;
  padding-bottom: 56%;
}
.react-player__preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */
.MuiTableCell-sizeSmall {
  font-size: 12px;
}

.MuiAutocomplete-tag{
  height: 26px !important;
}
.MuiAutocomplete-tag .MuiChip-deleteIcon{
  font-size: 18px !important;
}
.MuiAutocomplete-tag .MuiChip-label{
  padding-left: 8px !important;
}

@media screen and (max-width: 575px) {
  .MuiTableCell-stickyHeader {
    padding: 0 15px !important; 
  }
}


@media (prefers-color-scheme: dark) {
.variant_list .basicAnchor.active .MuiListItemButton-root {
  background: rgba(255, 255,255, 0.05) !important;
}
}