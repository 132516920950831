
@-webkit-keyframes headShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    6.5% {
        -webkit-transform: translateX(-10px) rotateY(-9deg);
        transform: translateX(-10px) rotateY(-9deg)
    }

    18.5% {
        -webkit-transform: translateX(9px) rotateY(7deg);
        transform: translateX(9px) rotateY(7deg)
    }

    31.5% {
        -webkit-transform: translateX(-7px) rotateY(-5deg);
        transform: translateX(-7px) rotateY(-5deg)
    }

    43.5% {
        -webkit-transform: translateX(6px) rotateY(3deg);
        transform: translateX(6px) rotateY(3deg)
    }

    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes headShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    6.5% {
        -webkit-transform: translateX(-10px) rotateY(-9deg);
        transform: translateX(-10px) rotateY(-9deg)
    }

    18.5% {
        -webkit-transform: translateX(9px) rotateY(7deg);
        transform: translateX(9px) rotateY(7deg)
    }

    31.5% {
        -webkit-transform: translateX(-7px) rotateY(-5deg);
        transform: translateX(-7px) rotateY(-5deg)
    }

    43.5% {
        -webkit-transform: translateX(6px) rotateY(3deg);
        transform: translateX(6px) rotateY(3deg)
    }

    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.animate__headShake {
    /* -webkit-animation-name: headShake;
    animation-name: headShake;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
     */
     animation: headShake ease-in-out 0.5s both;
}