/* .customCard {
    padding: 30px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    width: 100%;
    margin-bottom: 30px;
} */
.customCard {
    margin-bottom: 30px;
    border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
    padding-bottom: 20px;
}

.acntNameDetail strong {
    flex: 0 0 60px;
    max-width: 60px;
}

.acntNameDetail span {
    flex: 0 0 calc(100% - 60px);
    max-width: calc(100% - 60px);
}

.btnLink {
    display: inline-block;
    border: 0;
    background: transparent;
    color: var(--buttonColor);
    font-size: 14px;
}

.acntNameDetail {
    margin-bottom: 3px;
}

.btnLink.disabled {
    pointer-events: none;
}

.rounded-pill {
    letter-spacing: 0.5px;
}

.customCard__body {
    font-size: inherit;
}

.customCard__title {
    margin: 0;
}

.customCard__title .badge {
    font-size: 12px;
}

.orderHolder_inner .tabletxt+.tabletxt {
    margin-top: 5px;
}

.orderHolder {
    padding: 10px 0;
}

.orderHolder+.orderHolder {

    border-top: 1px solid var(--color-with-transparency);
}

.acntbckBttn {
    color: var(--color);
}

.addressHldrGrd {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 10px));
    grid-gap: 10px;
}

.template_main .addressHldrGrd .btn.btn-xsm {
    padding: 0.2rem 0.8rem;
    font-size: 14px;
}

.addressHldrMain {
    padding: 10px 0;
    display: block;
}

.addressHldrMain+.addressHldrMain {
    --transparency: 40%;
    --color-with-transparency: color-mix(in lch, var(--color) var(--transparency, 100%), transparent);
    border-top: 1px solid var(--color-with-transparency);
}


.orderHolder .tabletxt__img {
    width: 40px;
    margin: 0;
}

.orderHolder .tabletxt__content {
    width: calc(100% - 40px);
    padding-left: 10px;
}

.addressHldrMain__header {
    --transparency: 40%;
    --color-with-transparency: color-mix(in lch, var(--color) var(--transparency, 100%), transparent);
    border-bottom: 1px solid var(--color-with-transparency);
}

.addressHldrMain__header_main {
    background: rgba(0, 0, 0, 0.02);
    padding: 20px;
}

.customCardOrder {
    padding: 15px 0;
    margin-bottom: 20px;
}

.customCard .orderSummaryPart {
    padding: 0;
    background: transparent;
    position: static;
}

.customCardOrder .tabletxt {
    width: 100%;
}

.my_order_block {
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 3px;
}

.rounded-pill.border-pill {
    background: none !important;
    color: var(--color) !important;
    border: #ccc 1px solid;
}

.refund_products {
    display: flex;
}

.refund_products+.refund_products {
    border-top: #f3f2f2 1px solid;
    padding-top: 5px;
    margin-top: 5px;
}

.orderHolder.d-flex .orderHolder_inner {
    flex: 0 0 60%;
}

.tabletxt.tabletxtViewOrederItem {
    --widthx: 200px;
    align-items: normal;
}

.radioButtonList {
    list-style: none;
}

.radioButtonList li input {
    display: none;
}

.template_main {
    --transparency: 30%;
    --color-with-transparency: color-mix(in lch, var(--color) var(--transparency, 100%), transparent);

}

.ViewOrderDeliveryBox {
    border: 1px solid var(--color-with-transparency);
    padding-block: 7px;
    border-width: 1px 0;
}

.radioButtonList li label {
    display: block;
    margin: 0;
    position: relative;
    padding: 10px 15px;
    padding-left: 25px;
    border-bottom: 1px solid var(--color-with-transparency);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
}

.radioButtonList li .border-0 label {
    border: 0 !important;
}

.radioButtonList li label:hover {
    z-index: 1;
}

.radioButtonList li input {
    display: none;
}

.radioButtonList li label:before {
    --transparency: 40%;
    --color-with-transparency: color-mix(in lch, var(--color) var(--transparency, 100%), transparent);
    content: '';
    position: absolute;
    left: 0;
    top: 13px;
    width: 15px;
    height: 15px;
    border: 1px solid var(--color-with-transparency);

}

.radioButtonList li input[type="radio"]+label:before {
    border-radius: 100%;
}

.radioButtonList li input:checked+label:before {
    box-shadow: inset var(--white) 0 0 0px 2px;
    background: var(--color);
    ;
}

.customTabBtn .disabled.btn {
    opacity: 0.3;
}

.accordion-body .ViewOrderDeliveryBox:last-child {
    border-bottom: 0;
}

.ViewOrderDeliveryBox  .accordion-button {
    box-shadow: none;
}

@media only screen and (min-width: 1199px) {
    .orderHolder.d-flex .tabletxt {
        width: 100%;
    }
}

@media only screen and (max-width: 1199px) {
    .orderHolder {
        flex-direction: column;
    }

    .orderHolder * {
        text-align: left;
    }

    .orderHolder_inner {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 991px) {
    .tabletxt.tabletxtViewOrederItem {
        flex-direction: row;
    }
}

@media only screen and (max-width: 767px) {
    .orderHolder .tabletxt {
        flex-direction: row;
        align-items: flex-start;
        margin-left: 0;
    }

    .addressHldrGrd {
        grid-template-columns: 100%;
        grid-gap: 5px;
    }

    .refund_products {
        flex-direction: column;
    }

    .refund_products>div {
        flex: auto !important;
    }

    .tabletxt.tabletxtViewOrederItem {
        --widthx: 150px;
        align-items: normal;
    }
}

@media only screen and (max-width: 575px) {
    .customCard {
        margin-bottom: 20px;
    }

    .tabletxt.tabletxtViewOrederItem, .orderHolder .tabletxtOrderDetail {
        --widthx: 110px;
    }
}

@media only screen and (max-width: 370px) {
    .tabletxt.tabletxtViewOrederItem, .orderHolder .tabletxtOrderDetail {
        --widthx: 90px;
    }
}