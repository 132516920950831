.customFileUpload {
  width: 100%;
  height: auto;
  min-height: 100px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  border: 2px dashed #c4c4c4;
  padding: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filePreviewBox__cntnt {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.img_delete {
  position: absolute !important;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50% !important;
  background-color: #df0000 !important;
  box-shadow: rgba(0, 0, 0, .3) 2px 2px 3px;
}

.img_delete svg {
  fill: #fff;
  width: 15px;
}

.customFileUpload>input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
}

.singlefilePreviewBox .filePreviewBox__img img {
  max-height: 400px;
  vertical-align: middle;
}

.img_uploading {
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
}
.edit_image_icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  pointer-events: none;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, .3) 0 0 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: ease 0.3s;
}
.edit_image_icon svg{
  width: 20px;
}

.socialSharingImage .filePreviewBox__img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
    align-items: center;
    justify-content: center;
}

.filePreviewBox__img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  object-position: center center;
  -o-object-position: center center;
}
.socialSharingImage .filePreviewBox__img img {
  height: auto;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain;
  object-position: center center;
  -o-object-position: center center;
  max-width: 100%;
  max-height: 100%;
}
.customFileUpload:hover .edit_image_icon{
  opacity: 1;
  visibility: visible;
}