.template_main {
    --transparency: 40%;
    --color-with-transparency: color-mix(in lch, var(--color) var(--transparency, 100%), transparent);
}
.login_page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.login_page_inner {
    width: 100%;
    max-width: 515px;
    margin: auto;
    padding: 30px 15px;
}

.custom_input_group.input-group .input-group-text {
    background-color: transparent;
    border-radius: 0 !important;
    color: var(--color);
    font-size: 1.3rem;
    position: absolute;
    left: 0;
    z-index: 9;
    border-color: transparent;
    padding: 0.5rem 0;
}

.invalid-feedback {
    font-size: 12px;
}

.custom_input_group.input-group {
    position: relative;
}

.custom_input_group.input-group .form-control {
    padding: 0.5rem 10px;
    padding-left: 40px;
    border-radius: 0;
    font-size: inherit;
    border-color: var(--color-with-transparency);
    color: var(--color);
    border-width: 0 0 1px;
    background: transparent;
}

.custom_input_group.input-group.input-group-md .form-control {
    padding: 0.7rem 10px;
    padding-left: 40px;
}

.custom_input_group.input-group.input-group-lg .form-control {
    padding: 1rem 10px;
    padding-left: 45px;
}

.custom_input_group.input-group.input-group-md .input-group-text {
    padding: 0.7rem 0;
}

.custom_input_group.input-group.input-group-lg .input-group-text {
    padding: 1rem;
}

.custom_input_group.input-group .form-control:focus {
    box-shadow: none;
    border-color: var(--color-with-transparency);
}

.custom_input_group input.form-control.is-invalid {
    /* background: #ff000014; */
    border-color: #ff0000;
}

.custom_input_group.input-group .react-tel-input .flag-dropdown {
    border-right: 0;
    border-color: var(--color-with-transparency);
}

.custom_input_group.input-group .react-tel-input .selected-flag {
    padding: 0 0 0 15px;
}

.custom_input_group.input-group .react-tel-input .form-control {
    width: 100%;
    padding: 20px;
    padding-left: 40px;
    background: #fff;
    border: 1px solid var(--color-with-transparency);
    border-width: 0 0 1px;
}

.react-tel-input .country-list .search-box {
    margin-left: 0;
    width: 100%;
}

.loading_full_page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.form-control.is-invalid.password_field, .was-validated .form-control.password_field:invalid {
    background: none;
}

.login_account a {
    color: #6a6a6a;
    color: var(--buttonColor);
}

.custom_input_group.input-group .form-control:-webkit-autofill, 
.custom_input_group.input-group .form-control:-webkit-autofill:focus .custom_input_group.input-group .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px var(--bg) inset !important;
    -webkit-text-fill-color: var(--color);
}