@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Lato:wght@300;400;500;700&family=Open+Sans:wght@300;400;500&family=Montserrat:wght@300;400;500;700&family=Poppins:wght@300;400;500;700&family=Oswald:wght@300;400;500;700&family=Raleway:wght@300;400;500;700&family=DM+Serif+Display:ital@0;1&family=Bebas+Neue&family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Comfortaa:wght@300;400;500;600;700&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Abril+Fatface&display=swap');


@import url('../Assets/fonts/stylesheet.css');

html,
body,
#root,
#root>div {
  min-height: 100vh;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary: #f1592a;
  --black: #000;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #b1b1b1 #f9f9f9;
}

*::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f9f9f9;
}

*::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 20px;
  border: 1px solid #f9f9f9;
}




input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

img {
  max-width: 100%;
}

.basicAnchor {
  color: inherit;
  text-decoration: none;
}

.grabbable:last-child hr:last-child {
  display: none;
}

.smallContainer {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
}

*:focus,
*:focus-within {
  outline: none !important;
}

.headingCommon {
  margin-bottom: 10px !important;
}

.MuiDataGrid-cell .MuiSvgIcon-root {
  width: 0.8em;
  height: 0.8em;
}

.ml-0 {
  margin-left: 0 !important;
}

.float_btn {
  position: sticky !important;
  top: 80px;
}

.small_input * {
  font-size: 13px !important;
}

.fileUploader.active {
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -ms-grid;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(5, 1fr);
}

.active .customFileUpload {
  grid-column: 1/span 5;
}

.fileUploader .dropzone {
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed #c4c4c4;
  padding: 30px 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileUploader .dropzone p {
  margin: 0;
}

.fileUploader.active>div:first-child {
  grid-column: 1/span 2;
  grid-row: 1/span 2;
}

.filePreviewBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
}


.fileUploader.active .filePreviewBox__img {
  display: flex;
  align-items: center;
  min-width: 0px;
  overflow: hidden;
  min-height: 150px;
  margin: 0px auto;
}

.fileUploader.active div img:not(.fileUploader.active>div:first-child>div>img) {
  display: block;
  width: auto;
  /* max-height: 150px; */

}

.fixedloading {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1201 !important;
  height: 2px !important;
}


/* textEditorBox */
.textEditorBox {
  border-top: 0;
  /* min-height: 220px;
  max-height: 250px; */
  cursor: text;
  padding: 0 15px 15px;
  /* overflow-y: auto !important; */
  min-height: 220px;
}

.isDraftEditor .textEditorBox {
  max-height: 250px;

}

.textEditorBoxToolbar {
  margin: 0;
}

hr {
  border-top: #eee 1px solid;
  display: block;
}


.editor_code {
  /* position: absolute !important; */
  top: 0;
  right: 0;
  padding: 7px !important;
  min-width: inherit !important;
}

.html_editor {
  margin-top: 10px;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  border-top: 1px solid #eaeaea;
}

.html_editor .textEditorBox {
  /* border-top: #eee 1px solid; */
}

.contenList {
  padding-left: 20px;
}

.noHover {
  white-space: normal;
}

.noHover:hover,
.noHover:focus {
  background-color: inherit !important;
}

.MuiFormControl-root .react-tel-input .form-control {
  width: 100%;
  height: 42px;
  font-size: 14px;
  background: inherit;
  color: inherit;
}

.advance_reporting_alert .MuiAlert-message {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advance_reporting_alert .MuiAlert-message>span {
  margin: 0;
}

.noHover span * {
  display: none !important;
}

.addressHolder {
  border-bottom: 1px solid #eee;
  padding: 0 0 1rem;
}

.addressHolder+.addressHolder {
  padding-top: 1rem;
}

.addressHolder:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}

.notificationList:not(last-of-type) {
  border-bottom: 1px solid #ccc;
}

.MuiAccordion-rounded.Mui-expanded {
  box-shadow: 0px -2px 1px -1px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.MuiAccordion-rounded.Mui-expanded:first-of-type {
  box-shadow: 0px 0px 1px -1px rgb(0 0 0 / 15%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.customheightNumber .MuiOutlinedInput-input {
  box-sizing: border-box;
  height: 41px;
}

.qty_input>div {
  padding: 0 !important;
  display: block;
}

.qty_input>div input {
  text-align: center !important;
  padding: 6px 0 !important;
}

.qty_input>div fieldset {
  top: 0 !important;
}

.orderProductBoxCntnt__qntty .qty_input>div {
  padding: 0 14px 0 0 !important;
  display: flex;
}

.orderProductBoxCntnt__qntty.no_padding .qty_input>div {
  padding: 0 !important;
}

.css-89dkhe-MuiButtonBase-root-MuiButton-root:hover {
  color: #fff;
}

fieldset legend {
  float: left !important;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.MuiButton-contained:hover {
  color: #fff;
}

a.link {
  color: blue;
}

.MuiFormLabel-root.Mui-error {
  background: transparent;
}

.MuiFormLabel-root.Mui-error.MuiInputLabel-shrink {
  background-color: #fff;
}

.layout_sidebar .mini .rdw-option-wrapper {
  height: 100%;
}

.mini .rdw-inline-dropdown,
.mini .rdw-list-dropdown,
.mini .rdw-link-dropdown {
  width: 40px;
  margin: 0 1px;
}

.react-tel-input {
  font-size: inherit !important;
}

.react-tel-input .flag-dropdown {
  display: none;
}

.checkbox_label .MuiFormControlLabel-label {
  flex: 1;
}

.MuiFormControl-root .react-tel-input .form-control {
  padding-left: 15px;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-delay: 10ms;
}

.report_product_name:hover {
  text-decoration: underline !important;
}

.top_loader {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 9999 !important;
  width: 100%;
  height: 3px !important;
  margin: 0 !important;
}

.dropdownMain__price {
  white-space: nowrap;
}

.main_outer {
  display: flex;
  flex-direction: column;
}

.main_block {
  flex: 1 0 auto;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.inactive_store>img {
  pointer-events: none;
}

#menu-state .MuiPopover-paper,
#menu-province .MuiPopover-paper {
  max-height: 400px !important;
}

@media screen and (max-width:992px) {
  .main_outer {
    width: 100%;
  }
}

.react-datepicker-popper {
  z-index: 99;
}

.MuiFormControl-root .react-tel-input {
  margin-top: -5px;
}

/* .MuiFormControl-root.mt-0 .react-tel-input {
  margin-top: 0;
} */
label[data-shrink=false]+.MuiInputBase-formControl {
  /* height: 38px; */
  box-sizing: border-box;
}

.MuiFormControl-root .react-tel-input .form-control:focus {
  box-shadow: 0 0 0 !important;
}

input.form-control.small.datepicker {
  background: #fff;
}

.border-0 fieldset {
  top: 0 !important;
}

.menuHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  border: 1px solid #f3f3f3;
}

.menuHead__leftIcon {
  margin-right: 10px;
}

.menu_item_list span+span:before {
  content: ", "
}

.MuiAvatar-root img {
  object-fit: inherit !important;
}

.react-datepicker-wrapper {
  max-width: 130px;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.NoResult_block {
  max-width: 500px;
  margin: auto;
  line-height: 1.6;
}

.fileUploader.fileUploader__prefrence.socialSharingImage .filePreview2Box__img img {
  max-height: 155px;
}


.theme_thumbnail {
  position: relative;
}

.theme_thumbnail_btns {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: ease 0.3s;
  opacity: 0;
  pointer-events: none;
}

.theme_thumbnail_btns:after {
  content: "";
  position: absolute;
  inset: 0;
  background: #fff;
  opacity: 0.8;
  z-index: -1;
}

.theme_thumbnail:hover .theme_thumbnail_btns {
  opacity: 1;
  pointer-events: all;
}


.drfat_theme_popover {
  position: absolute;
  top: 20px;
  right: -15px;
  background-color: #fff;
  border-radius: 10px;
  border: #ddd 1px solid;
  overflow: hidden;
  min-width: 120px;
  z-index: 99;
}

.MuiAutocomplete-noOptions {
  display: none;
}

.btn-primary {
  background-color: #c0360c;
  color: #fff;
}

.draft_theme_backdrop {
  position: fixed;
  inset: 0;
  z-index: 9;
  cursor: pointer;
}

.order_float_btn {
  position: sticky;
  bottom: -20px;
  background: var(--white);
  z-index: 9;
  padding: 0px 0 10px;
}

input.theme_rename_input {
  border-width: 0 0 1px;
}

div#notistack-snackbar {
  max-width: 600px;
}

.variant_list {
  max-height: 450px;
  overflow: hidden;
  overflow-y: auto;
}

.variant_main_img .variant_image {
  border: 0;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 11px;
  font-weight: 400;
}

.fullWidthQuantity {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.fullWidthQuantity__child {
  width: 40px !important;
}

.fullWidthQuantity .qty_input>div fieldset {
  border: 0;
  box-shadow: none;
  outline: 0;
}

.cstmlocationDropdown fieldset {
  border: 0;
}

.cstmlocationDropdown .MuiFormControl-root {
  margin-left: 0;
}

.cstmlocationDropdown .MuiFormControl-root .MuiSelect-select {
  padding-left: 6px;
}

.rdw-editor-toolbar {
  padding: 6px 46px 0 5px;
  background-color: var(--white) !important;
}

.rdw-dropdown-wrapper {
  background-color: var(--white) !important;
}

.pre_tag {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 10px;
  background-color: #eee;
  border-radius: 5px;
  margin: 5px 0;
}

.MuiDialog-container .MuiPaper-root[aria-labelledby="xlModal"] {
  width: 100%;
  max-width: 900px;
}

.MuiDialog-container .MuiPaper-root[aria-labelledby="largeModal"] {
  width: 100%;
  max-width: 700px;
}

.MuiDialog-container .MuiPaper-root[aria-labelledby="mdModal"] {
  width: 100%;
  max-width: 600px;
}

.add_media>* {
  cursor: pointer;
}

.add_media>input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
}


@media only screen and (max-width: 1300px) {
  .fileUploader.active {
    grid-template-columns: repeat(3, 1fr);
  }

  .fileUploader.active>div:first-child {
    grid-column: 1/span 2;
    grid-row: 1/span 2;
  }

  .active .customFileUpload {
    grid-column: 1/span 3;
  }
}

@media only screen and (min-width: 599px) {
  .draft_theme_outer {
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 599px) {
  .MuiList-root.customTabScroll {
    display: flex;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }
}

@media only screen and (max-width: 575px) {
  .fileUploader.active {
    grid-template-columns: repeat(2, 1fr);
  }

  .fileUploader.active>div:first-child {
    grid-column: 1/span 1;
    grid-row: 1/span 1;
  }

  .active .customFileUpload {
    grid-column: 1/span 2;
  }
}

.rdw-dropdown-optionwrapper {
  background: var(--white) !important;
}

.view_cart_input {
  width: 100%;
  padding: 5px 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  border: 0;
  margin-bottom: 15px;
}

.token.keyword,
.token.property,
.token.selector,
.token.constant,
.token.symbol,
.token.builtin {
  color: hsl(255.29deg 84.74% 69.03%) !important;
}

.instagram_button>button {
  background: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.instagram_button button.connect_btn {
  border: 0;
  color: #f1592a;
  background-color: transparent;
  font-family: Aktiv Grotesk;
  font-weight: 600;
  font-size: 0.7777777777777778rem;
  line-height: 1.75;
  text-transform: none;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
}

.preview_social {
  max-height: 130px;
  height: 100%;
  overflow: hidden;
}

.preview_social video {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.subscribe_btn {
  background-color: #e62214;
  color: #fff;
  width: 92px;
  text-align: center;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
}

.preview_social.twitter {
  display: grid;
  gap: 2px;
  grid-auto-rows: auto;
  border-radius: 12px;
  overflow: hidden;
  min-height: 200px;
}

.preview_social.twitter[data-length="2"],
.preview_social.twitter[data-length="3"],
.preview_social.twitter[data-length="4"] {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}


.preview_social.twitter[data-length="2"] .preview_social_img,
.preview_social.twitter[data-length="3"] .preview_social_img,
.preview_social.twitter[data-length="4"] .preview_social_img {
  grid-area: span 1 / span 6;
}

.preview_social.twitter[data-length="3"] .preview_social_img:first-child {
  grid-area: span 2 / span 6;
}

.preview_social.twitter[data-length="3"] .preview_social_img:nth-child(2),
.preview_social.twitter[data-length="3"] .preview_social_img:nth-child(3),
.preview_social.twitter[data-length="4"] .preview_social_img {
  height: 100px;
}

.preview_social_img video,
.preview_social_img img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.preview_social.youtube.post {
  position: relative;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  border-radius: 5px;
  overflow: hidden;
}

.preview_social.youtube.post img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview_social.youtube.post>svg {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #eee;
  border-radius: 5px 0 0;
}
.mapImgHolder.iframeMap iframe {
  width: 100%;
  min-height: 300px;
  vertical-align: top;
  aspect-ratio: 16/5;
}
#menu_infinitescroll{
  border: 1px solid #d9d9d9;
}
.expireStore {
  width: 100%;
  padding: 30px;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (prefers-color-scheme: dark) {

  .preview_social.youtube.post,
  .preview_social.youtube.post>svg {
    background-color: #161616;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #626262 #2a2a2a;
  }

  .MuiPaper-root {
    background-image: none !important;
  }

  .MuiPaper-root .MuiInputLabel-outlined {
    --white: #121212;
  }

  .react-tel-input .form-control.invalid-number {
    background-color: #400000 !important;
  }

  a.link {
    color: #8585ff;
  }

  *::-webkit-scrollbar-track {
    background: #2a2a2a;
  }

  .MuiTypography-colorTextSecondary,
  .rdw-option-wrapper,
  .rdw-dropdown-carettoopen,
  .rdw-dropdown-selectedtext * {
    filter: invert(1);
  }

  .rdw-dropdown-selectedtext span {
    color: #fff;
    filter: none;
  }

  .rdw-editor-toolbar {
    border-color: #181818;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #2a2a2a;
    border-radius: 20px;
    border: 1px solid #626262;
  }

  .MuiBox-root .MuiTableCell-head,
  .MuiBox-root .MuiTableCell-body {
    color: #fff;

  }

  .MuiBox-root .MuiTableCell-root {
    border-color: rgb(55 55 55);
    background-color: var(--white);
  }

  .MuiBox-root .css-fuckdy,
  .MuiBox-root .css-n4smsa,
  .MuiBox-root .css-9bpz5b {
    border-color: #646464;
  }

  .pre_tag,
  .rdw-dropdownoption-active,
  .rdw-dropdownoption-highlighted {
    background-color: #121212;
  }

  .view_cart_input {
    background-color: #323232;
    color: #fff;

  }

  .rdw-image-modal,
  .rdw-image-modal-header-option,
  li.rdw-link-dropdownoption *,
  .rdw-dropdown-optionwrapper *,
  .rdw-link-modal,
  .rdw-link-modal *,
  .rdw-colorpicker-modal,
  .rdw-colorpicker-modal * {
    filter: invert(1);
    box-shadow: none;
  }

  .rdw-colorpicker-modal * {
    color: #000;
  }

  .rdw-emoji-modal {
    background-color: #212121;
    border-color: #000;
    box-shadow: none;
  }

  .rdw-dropdown-wrapper {
    box-shadow: none !important;
    border-color: #212121;
  }

  .rdw-dropdown-optionwrapper {
    border-color: var(--white) !important;
  }

  .rdw-link-dropdownoption,
  .rdw-dropdown-optionwrapper li {
    background: #666;
  }

  .leftNavbarContainer span.cart_items {
    color: var(--white);
  }
}